import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "form","preview" ]

    initialize() {
    }

    refresh(event) {
        //  reload the iframe
        this.previewTarget.src = this.previewTarget.src;
    }
}