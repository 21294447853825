import ApplicationController from "./application_controller";
import YT from "youtube-player";


export default class extends ApplicationController {
    static targets = [ "video" ]

    connect() {

    }

    play() {
        YT.load(this.videoTarget.id, {
            events: {
                onReady: (event) => {
                    event.target.playVideo();
                }
            }
        });
    }

}
