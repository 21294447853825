import ApplicationController from "./application_controller";


export default class extends ApplicationController {
    static targets = [ 'list', 'form' ]

    connect() {
        //console.log('view_preferences connected')
    }

    submitForm() {
        // this.formTarget.requestSubmit();
        // trigger a reload of the turbo frame with the listTarget
        // this.listTarget.src = this.listTarget.src;
        // reload the page
        // window.location.reload();
    }

}
