import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect() {
    this.element.placeholder = "DD.MM.YYYY hh:mm"
    new Cleave(this.element, {
      delimiters: [".", ".", " ", ":"],
      blocks: [4, 2, 2, 2, 2]
    })
  }

}
