import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect() {
    this.element.placeholder = "hh:mm:ss"
    new Cleave(this.element, {
      time: true,
      timePattern: ["h", "m", "s"]
    })
  }

}
