import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect() {
    $(this.element).select2()
  }

  disconnect() {
    $(this.element).select2("destroy")
  }

}
