// app/javascript/controllers/contacts_controller.js

import ApplicationController from "./application_controller";
export default class extends ApplicationController {
    static targets = ["customerSelect", "contactSelect"];

    updateContacts() {
        const customerId = this.customerSelectTarget.value;
        const contactSelect = this.contactSelectTarget;

        // Fetch contacts for the selected customer via an AJAX request
        fetch(`/backend/customers/${customerId}/contacts.json`)
            .then(response => response.json())
            .then(data => {
                // Clear existing options
                contactSelect.innerHTML = '';

                // Add a blank option
                contactSelect.appendChild(new Option('Select a contact', ''));

                // Add the fetched contacts to the select field
                data.contacts.forEach(contact => {
                    contactSelect.appendChild(new Option(contact.name, contact.id));
                });
            });
    }
}
