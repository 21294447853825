import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["modal"]
    reject(event) {
        event.preventDefault()

        // find the first element which id begins with "applies-reject-modal"
        let modal = this.modalTarget.querySelector("[id^='applies-reject-modal']")
        modal.classList.remove('hidden')
        document.body.innerHTML += '<div class="modal-backdrop fade show"></div>';
    }
}
