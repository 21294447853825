import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["unhide"]

    connect() {
        //console.log("connect");
    }

    password(e) {
        if (this.input.type === "password") {
            e.target.classList.remove('fa-eye-slash');
            e.target.classList.add('fa-eye');
            this.input.type = "text";
        } else {
            e.target.classList.remove('fa-eye');
            e.target.classList.add('fa-eye-slash');
            this.input.type = "password";
        }
    }
}