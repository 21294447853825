import ApplicationController from "./application_controller"

import Swiper from 'swiper/swiper-bundle'

export default class extends ApplicationController {


    static values = {
        options: Object
    }

    connect () {
        this.swiper = new Swiper(this.element, {
            ...this.defaultOptions,
            ...this.optionsValue
        })
    }

    disconnect () {
        this.swiper.destroy()
        this.swiper = undefined
    }

    get defaultOptions (){
        return {
            loop: true
        }
    }
}