import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static get targets() {
        return ['value', 'input', 'category','documentType']
    }

    connect() {
    }

    display(event) {

        const fileName = event.target.value.split('\\').pop();
        this.valueTarget.innerHTML = fileName;

        if (this.documentTypeTarget) {
            this.documentTypeTarget.classList.remove('hidden').add('animate__animated', 'animate__fadeIn');
        }
        // get all attachment categories into an array from /attachment_categories.json
        // const attachment_categories = JSON.parse('/attachment_categories.json')
        //
        //
        // if (fileName.includes('Bewerbung')) {
        //     console.log('Bewerbung')
        //     this.categoryTarget.value = 'Bewerbung';
        // } else if (fileName.includes('Anschreiben')) {
        //     console.log('Anschreiben')
        //     this.categoryTarget.value = 'Lebenslauf';
        // } else if (fileName.includes('Lebenslauf')) {
        //     console.log('Lebenslauf')
        //     this.categoryTarget.value = 'Lebenslauf';
        // } else {
        //     console.log('other')
        //     this.categoryTarget.value = 'Sonstiges';
        // }

    }
}