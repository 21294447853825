import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["sidebar"];

    connect() {
        this.sidebar = this.targets.find("sidebar");
        this.resizing = false;
        this.lastDownX = 0;

        // Add event listeners
        this.sidebar.addEventListener('mousedown', (event) => this.startResize(event));
        document.addEventListener('mousemove', (event) => this.resize(event));
        document.addEventListener('mouseup', () => this.stopResize());
    }

    startResize(event) {
        // Check if the click is within 10px of the left edge
        if (event.clientX - this.sidebar.getBoundingClientRect().left < 20) {
            // change the cursor to move
            this.sidebar.style.cursor = 'col-resize';
            this.resizing = true;
            this.lastDownX = event.clientX;
        }
    }

    resize(event) {
        if (!this.resizing) return;
        // Use requestAnimationFrame for smooth rendering
        window.requestAnimationFrame(() => {
            const newWidth = this.sidebar.offsetWidth - (event.clientX - this.lastDownX);
            this.lastDownX = event.clientX;

            // Ensure the new width is not less than 250px
            if (newWidth >= 320) {
                this.sidebar.style.width = `${newWidth}px`;
            }
        });
    }

    stopResize() {
        if (this.resizing) {
            this.resizing = false;
            this.sidebar.style.cursor = 'auto';
        }
    }
}