import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  static targets = [ 'startTime', 'interactionType', 'title', 'recipient' ]

  setToday() {
    const today = new Date();
    today.setHours(today.getHours() + 1);
    this.startTimeTarget._flatpickr.setDate(today);
  }

  setTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.startTimeTarget._flatpickr.setDate(tomorrow);
  }

  setNextWeek() {
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    this.startTimeTarget._flatpickr.setDate(nextWeek);
  }

  setNextMonth() {
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    this.startTimeTarget._flatpickr.setDate(nextMonth);
  }

  setInteractionType(event) {
    // when interactionType is set, update the Title to
    // reflect the interactionType
    const interactionType = event.target.value;
    const title = this.titleTarget.value;
    // interactiontype mit applicant name
    const recipient = this.recipientTarget.value;
    this.titleTarget.value = `${interactionType} mit ${recipient}`;
  }
}
