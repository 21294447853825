// src/controllers/connect-controller.js
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["modal"];

    launchConnect() {
        let modalController = this.application.getControllerForElementAndIdentifier(
            this.modalTarget,
            "modal"
        );
        modalController.open();
    }
}