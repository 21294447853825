import ApplicationController from "./application_controller";

//import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelect from 'tom-select'
import 'tom-select/dist/esm/plugins/remove_button/plugin'
import 'tom-select/dist/esm/plugins/dropdown_input/plugin'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'

const selectInput = document.getElementById('applicant_tag_list')

export default class extends ApplicationController {
    static targets = [ "input" ]

    connect() {


        this.tomselect = new TomSelect(this.inputTarget, {
            placeholder: "Bitte auswählen",
            searchable: true,
            create: false,
            maxItems: 1,
            multiple: false,
            render: {
                option: function (data, escape) {
                    return '<div>' +
                        '<span class="block">' + escape(data.name) + '</span>' +
                        '<span class="text-gray-400">' + escape(data.email) + '</span>' +
                        '</div>';
                }
            }

        })


    }

    disconnect() {
        this.tomselect.destroy(this.inputTarget)
    }
}