import ApplicationController from "./application_controller";
import { get } from "@rails/request.js"
import TomSelect from "tom-select";

export default class extends ApplicationController {
    static targets = ["select"]
    static values = {
        url: String,
        param: String
    }

    connect() {
        if (this.selectTarget.id === "") {
            this.selectTarget.id = Math.random().toString(36)
        }
    }

    change(event) {
        let params = new URLSearchParams()


        // Check if the selectTarget is a TomSelect
        if (this.selectTarget.tomselect instanceof TomSelect) {
            // Use TomSelect's getValue method to get the selected value
            params.append(this.paramValue, this.selectTarget.tomselect.getValue())
        } else {
            params.append(this.paramValue, event.target.selectedOptions[0].value)
        }



        // keep an empty select option in the target select element
        // to prevent the select element from being disabled
        // when the target select element has no options
        if (params.get(this.paramValue) === "") {
            return
        } else {
            params.append("target", this.selectTarget.id)


        // when the select element has no options, load all records
        // by setting the param to an empty string
        //if (this.selectTarget.options.length === 0) {
        //    params.set(this.paramValue, "")
        //}

        get(`${this.urlValue}?${params}`, {
            responseKind: "turbo-stream",
            onSuccess: (response) => {
                if (this.selectTarget.tomselect instanceof TomSelect) {
                    // Clear all selected items
                    this.selectTarget.tomselect.clear()

                    // Add new options to the select element
                    // Assuming response.data contains the new options
                    response.data.forEach(option => {
                        this.selectTarget.tomselect.addOption(option)
                    })
                }
            }
        })
        }
    }
}