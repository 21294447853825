// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { German } from 'flatpickr/dist/l10n/de.js'
import { Austria} from 'flatpickr/dist/l10n/at.js'

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {

    initialize() {
        // sets your language (you can also set some global setting for all time pickers)

        this.config = {
            locale: Austria,
            time_24hr: true,
            allowInput: true,
            altInput: true,
            altFormat: 'd.m.Y',
            dateFormat: 'd.m.Y',
            altInputClass: "tw-form-control input"
        }
    }
}
