import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {

    static targets = [ "pool", "modal" ]

    connect() {
        super.connect()
    }

    toggle() {

        if (this.checked.length > 0) {
            // set active if not already
            if (!this.modalTarget.active) {
                this.modalTarget.classList.remove('hidden');
            }
        } else {
                this.modalTarget.classList.add('hidden');
        }
    }

    moveToPool(event) {


        event.preventDefault()

        let data = new FormData()
        this.checked.forEach(
            (checkbox) => data.append("ids[]", checkbox.value)
        )

        data.append("pool_id", this.poolTarget.value)
        let token = document.querySelector('meta[name="csrf-token"]').content;


        if (this.poolTarget.value != "") {
            alert(this.checked.length + " Bewerber werden verschoben in Pool " + this.poolTarget.value)


            fetch("/backend/applicants/bulk_move_to_pool", {
                method: "PUT",
                headers: {
                    "X-CSRF-Token": token,
                },
                body: data

            }).then((response) => {
                if (response.redirected) {
                    window.location.href = response.url;
                }
            });
        }
    }

}
