import Shepherd from 'shepherd.js'

import ApplicationController from "./application_controller";

export default class extends ApplicationController {

    connect() {
        if (!localStorage.getItem('inrivacareerportal-tour')) {
            this.showTour();
        }
    }

    showTour()  {
        const tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                classes: 'shadow-md bg-blue-100',
                scrollTo: true,
                cancelIcon: {
                    enabled: true
                }
            },
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
            }
        });

        tour.on('complete', this.dismissTour)
        tour.on('hide', this.dismissTour)

        tour.addStep({
            id: 'dashboard-greeting',
            title: "<%= I18n.t('onboarding.dashboard.greeting.title') %>",
            text: "<%= I18n.t('onboarding.dashboard.greeting.text') %>",
            attachTo: {
                element: '.dashboard-greeting',
                on: 'bottom'
            },
            buttons: [
                {
                    text: "<%= I18n.t('onboarding.common.next') %>",
                    action: tour.next
                },
                {
                    text: "<%= I18n.t('onboarding.common.cancel') %>",
                    action: tour.cancel,
                    classes: 'shepherd-button-secondary',
                },
                {   text: "<%= I18n.t('onboarding.common.close') %>",
                    action: function () {
                        tour.complete();
                        return this.hide();
                    },
                    classes: 'shepherd-button-secondary',
                }
            ]
        })

        tour.addStep({
            id: 'dashboard-charts',
            text: "<%= I18n.t('onboarding.dashboard.charts.text') %>",
            attachTo: {
                element: '.dashboard-charts',
                on: 'bottom'
            },
            buttons: [
                {
                    text: "<%= I18n.t('onboarding.common.back') %>",
                    action: tour.back,
                    classes: 'shepherd-button-secondary'
                },
                {
                    text: "<%= I18n.t('onboarding.common.next') %>",
                    action: tour.next
                },
                {
                    text: "<%= I18n.t('onboarding.common.later') %>",
                    action: tour.cancel,
                    classes: 'shepherd-button-secondary'
                }
            ]
            })

        tour.addStep({
            id: 'dashboard-actions',
            text: "<%= I18n.t('onboarding.dashboard.actions.text') %>",
            attachTo: {
                element: '.dashboard-actions',
                on: 'bottom'
            },
            buttons: [
                {
                    text: "<%= I18n.t('onboarding.common.back') %>",
                    action: tour.back,
                    classes: 'shepherd-button-secondary'
                },
                {
                    text: "<%= I18n.t('onboarding.common.next') %>",
                    action: tour.next
                },
                {
                    text: "<%= I18n.t('onboarding.common.later') %>",
                    action: tour.cancel,
                    classes: 'shepherd-button-secondary'
                }
            ]
        })

            tour.addStep({
                id: 'dashboard-pools',
                text: "<%= I18n.t('onboarding.dashboard.pools.text') %>",
                attachTo: {
                    element: '.dashboard-pools',
                    on: 'bottom'
                },
                buttons: [
                    {
                        text: "<%= I18n.t('onboarding.common.back') %>",
                        action: tour.back,
                        classes: 'shepherd-button-secondary'
                    },
                    {
                        text: "<%= I18n.t('onboarding.common.next') %>",
                        action: tour.next
                    },
                    {
                        text: "<%= I18n.t('onboarding.common.later') %>",
                        action: tour.cancel,
                        classes: 'shepherd-button-secondary'
                    }
                ]
            })

        tour.addStep({
            id: 'dashboard-gdpr',
            text: "<%= I18n.t('onboarding.dashboard.gdpr.text') %>",
            attachTo: {
                element: '.dashboard-gdpr',
                on: 'bottom'
            },
            buttons: [
                {
                    text: "<%= I18n.t('onboarding.common.back') %>",
                    action: tour.back,
                    classes: 'shepherd-button-secondary'
                },
                {
                    text: "<%= I18n.t('onboarding.common.next') %>",
                    action: tour.next
                },
                {
                    text: "<%= I18n.t('onboarding.common.later') %>",
                    action: tour.cancel,
                    classes: 'shepherd-button-secondary'
                }
            ]
        })

        tour.addStep({
            id: 'dashboard-tasks',
            text: "<%= I18n.t('onboarding.dashboard.tasks.text') %>",
            attachTo: {
                element: '.dashboard-tasks',
                on: 'bottom'
            },
            buttons: [
                {
                    text: "<%= I18n.t('onboarding.common.back') %>",
                    action: tour.back,
                    classes: 'shepherd-button-secondary'
                },
                {
                    text: "<%= I18n.t('onboarding.common.next') %>",
                    action: tour.next
                },
                {
                    text: "<%= I18n.t('onboarding.common.later') %>",
                    action: tour.cancel,
                    classes: 'shepherd-button-secondary'
                }
            ]
        })

        tour.addStep({
            id: 'dashboard-team',
            text: "<%= I18n.t('onboarding.dashboard.team.text') %>",
            attachTo: {
                element: '.dashboard-team',
                on: 'bottom'
            },
            buttons: [
                {
                    text: "<%= I18n.t('onboarding.common.back') %>",
                    action: tour.back,
                    classes: 'shepherd-button-secondary'
                },
                {
                    text: "<%= I18n.t('onboarding.common.next') %>",
                    action: tour.next
                },
                {
                    text: "<%= I18n.t('onboarding.common.later') %>",
                    action: tour.cancel,
                    classes: 'shepherd-button-secondary'
                }
            ]
        })

        tour.addStep({
            id: 'dashboard-activities',
            text: "<%= I18n.t('onboarding.dashboard.activities.text') %>",
            attachTo: {
                element: '.dashboard-activities',
                on: 'bottom'
            },
            buttons: [
                {
                    text: "<%= I18n.t('onboarding.common.back') %>",
                    action: tour.back,
                    classes: 'shepherd-button-secondary'
                },
                {
                    text: "<%= I18n.t('onboarding.common.next') %>",
                    action: tour.next
                },

                {
                    text: "<%= I18n.t('onboarding.common.later') %>",
                    action: tour.cancel,
                    classes: 'shepherd-button-secondary'
                }
            ]
        })

        tour.addStep({
            id: 'dashboard-finished',
            title: "<%= I18n.t('onboarding.dashboard.finished.title') %>",
            text:  "<%= I18n.t('onboarding.dashboard.finished.text') %>",
            buttons: [
                {
                    text: "<%= I18n.t('onboarding.common.back') %>",
                    action: tour.back,
                    classes: 'shepherd-button-secondary'
                },
                {
                    action: function () {
                        tour.complete();
                        return this.hide();
                    },
                    text: "<%= I18n.t('onboarding.common.finished') %>"
                }
            ]
        })

        tour.starI18n.t();
    }

    dismissTour() {
        if(!localStorage.getItem('inrivacareerportal-tour')) {
            localStorage.setItem('inrivacareerportal-tour', 'yes');
        }
    }


}
