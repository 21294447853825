import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "searchResults", "searchItem" ]

    requestSearch(){
        const resultsArea = document.querySelector('.jobs-search-results')
        if (this.searchItemTarget.value.length > 0){
            resultsArea.classList.add("showing")
            fetch('/jobs/query/?search='+ this.searchItemTarget.value)
                .then(response=> {
                    return response.text();
                })
                .then(html=> {
                    resultsArea.innerHTML=html;
                });
        } else {
            resultsArea.classList.remove("showing")
        }

    }
}