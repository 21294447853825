import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  static targets = [ "container" ]

  connect() {
    new Waypoint.Infinite({
      element: this.containerTarget,
      items: "[data-target='infinite-page.item']",
      more: "a[data-target='infinite-page.more']",
      loadingClass: "loading",
    })
  }

}
