import ApplicationController from "./application_controller";
import TomSelect from "tom-select";

export default class extends ApplicationController {

    connect() {
        this.tomselect = new TomSelect(this.element, {
            create: false,
            load: this.search.bind(this)
        });
    }

    search(query, callback) {
        if (!query.length) return callback();
        fetch(`/backend/jobsites/search?query=${query}`)
            .then(response => response.json())
            .then(places => {
                callback(places.map(place => ({ value: place.name, text: place.name })));
            });
    }

}
