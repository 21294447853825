import { put } from "@rails/request.js";
import Sortable from "sortablejs";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {

    /*
     * Usage
     * =====
     *
     * add data-controller="sortable" to common ancestor
     *
     * 1. Add data-sortable-url-value="URL to update position" to the
     * controller container
     *
     * 2. Add data-sortable-handle to the drag handle:
     *
     * 3. Add data-sortable-id to the item
     *
     */

    static values = { url: String };

    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.onEnd.bind(this),
            handle: "[data-sortable-handle]",
        });
    }

    disconnect() {
        this.sortable.destroy();
    }

    onEnd(event) {
        const { newIndex, item } = event;
        const id = item.getAttribute("data-sortable-id");
        const url = this.urlValue.replace(":id", id);
        // check if url value is set
        if (!url) {
            //console.error("No URL set for sortable controller");
            return;
        } else {
            put(url, {
                body: JSON.stringify({ position: newIndex }),
            });
        }
    }

}