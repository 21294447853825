import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "input", "follower" ]

    connect() {
    }

    handleChange() {
        const searchTerm = this.inputTarget.value.toLowerCase()

        this.followerTargets.forEach((follower) => {
            const name = follower.querySelector('.follower-name').textContent.toLowerCase()
            const highlightedName = name.replace(new RegExp(searchTerm, "gi"), (match) => {
                return `<span class="highlight">${match}</span>`
            })
            follower.style.display = highlightedName.includes('<span class="highlight">') ? 'block' : 'none'
            follower.querySelector('.follower-name').innerHTML = highlightedName
        })
    }
}
