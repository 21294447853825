import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static get targets() {
        return ["form"]
    }

    connect() {
        super.connect();
    }

    submit(event) {
        this.formTarget.requestSubmit()
    }
}