import ApplicationController from "./application_controller";

export default class extends ApplicationController {

    connect() {
        document.addEventListener("click", e => {
            if (e.target.matches("a[href^='#']")) {
                e.preventDefault()
                this.smoothScroll(e.target.getAttribute("href"))
            }
        })
    }

    smoothScroll(id) {
        let element = document.querySelector(id)
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
            this.activateTab(element)
            this.flashContainer(element)
        }
    }

    flashContainer(element) {
        element.classList.add("flash")
        setTimeout(() => {
            element.classList.remove("flash")
        }, 1000)
    }

    activateTab(element) {
        let tab = element.closest(".tab-container")
        if (tab) {
            tab.classList.remove("hidden")
        }
    }
}
