import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  static targets = [ 'startTime', 'endTime', 'location', 'locationType' ]

  updateEndTime() {
    // add 30 minutes to the start time
    const startTime = this.startTimeTarget.value;
    const endTime = new Date(startTime);
    endTime.setMinutes(endTime.getMinutes() + 60);
    this.endTimeTarget._flatpickr.setDate(endTime);
  }

  updateLocationType() {
    const location = this.locationTypeTarget.value;
    if (location === 'msteams' || location === 'zoom') {
      this.locationTarget.value = '';
    }

  }

  setToday() {
    const today = new Date();
    today.setHours(today.getHours() + 1);
    this.startTimeTarget._flatpickr.setDate(today);
    this.updateEndTime();
  }

  setTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.startTimeTarget._flatpickr.setDate(tomorrow);
    this.updateEndTime();
  }

  setNextWeek() {
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    this.startTimeTarget._flatpickr.setDate(nextWeek);
    this.updateEndTime();
  }

  setNextMonth() {
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    this.startTimeTarget._flatpickr.setDate(nextMonth);
    this.updateEndTime();
  }
}
