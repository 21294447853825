// Purpose: Controller for the apply_cta component.

import ApplicationController from "./application_controller";
export default class extends ApplicationController {
    static targets = ["cta", "arrowup"];

    connect() {
        // When the user scrolls down 100px from the top of the document, show the button
        window.onscroll = () => this.scrollFunction();
    }

    // when the user scrolls down 100px from the top of the document, show the button
    scrollFunction() {
        if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
            this.ctaTarget.style.display = "block";
            this.arrowupTarget.style.display = "block";
        } else {
            this.ctaTarget.style.display = "none";
            this.arrowupTarget.style.display = "none";
        }
    }

}
