import Rails from "@rails/ujs";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    onPostSuccess(event) {
        console.log("success!");
    }

    update() {
        Rails.fire(this.element, 'submit');
    }
}
