import ApplicationController from "./application_controller";

export default class extends ApplicationController {

    open() {
        document.body.classList.add("is-active");
        this.element.setAttribute("style", "display: block;");
        this.element.classList.add("show");
        document.body.innerHTML += '<div class="modal-backdrop fade show"></div>';
    }

    close() {
        document.body.classList.remove("is-active");
        this.element.removeAttribute("style");
        this.element.classList.remove("show");
        document.getElementsByClassName("modal-backdrop")[0].remove();
    }

}
