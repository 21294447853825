import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect() {
    this.element.placeholder = "DD.MM.YYYY"
    new Cleave(this.element, {
      date: true,
      datePattern: ["d","m","Y",]
    })
  }

}
