// filters_controller.js
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["filter"];

    filter() {
        const url = `${window.location.pathname}?${this.params}#filter`;

        Turbo.clearCache();
        Turbo.visit(url);
    }

    get params() {
        return this.filterTargets.map((t) => `${t.name}=${t.value}`).join("&");
    }
}