import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["button", "indicator", "checkbox"]

    toggle() {
        this.buttonTarget.classList.toggle("bg-indigo-600")
        this.buttonTarget.classList.toggle("bg-gray-200")
        this.indicatorTarget.classList.toggle("translate-x-5")
        this.indicatorTarget.classList.toggle("translate-x-0")
        this.checkboxTarget.toggleAttribute("checked")
    }
}
