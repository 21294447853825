require('bootstrap-select/js/bootstrap-select.js');

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "input" ]

    connect() {
        if (!$(this.inputTarget).data('selectpicker')) { // Check if selectpicker is already initialized
            $(this.inputTarget).selectpicker({
                virtualScroll: 25
            });
        }
    }

    disconnect() {
        $(this.inputTarget).selectpicker("destroy")
    }
}