import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ["sidebar", "closeButton"];

    connect() {
    }
    open(event) {
        // Show the sidebar
        this.sidebarTarget.classList.remove("hidden");
        this.sidebarTarget.classList.add("block");
    }

    close() {
        // Hide the sidebar
        this.sidebarTarget.classList.remove("block");
        this.sidebarTarget.classList.add("hidden");
    }
}
