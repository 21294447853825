import ApplicationController from "./application_controller";
//import { fetch } from "@hotwired/turbo-rails";

export default class extends ApplicationController {

    static targets = [ "listboxOption", "listbox" ]

    toggle(event) {
        event.preventDefault()
        this.listboxTarget.classList.toggle("hidden")
    }

    updateActive(event) {
        event.preventDefault()
        const jobID = this.data.get("job-id")
        const value = this.data.get("value")


    }
}