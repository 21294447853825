import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    // Get the new order of the options
    const newOrder = Array.from(this.element.children).map((child) => child.value);

    // Logic to handle the change in order goes here
    // For example, you can make an AJAX request to update the order in the backend
    // Here, we're just logging the new order to the console
  }

  disconnect() {
    this.sortable.destroy()
  }
}