import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
    static targets = [ "pool", "modal", "job" ]

    toggle() {

        if (this.checked.length > 0) {
            // set active if not already
            if (!this.modalTarget.active) {
                this.modalTarget.classList.remove('hidden');
            }
        } else {
                this.modalTarget.classList.add('hidden');
        }
    }

    destroy(event) {

        let confirmed = confirm("Sind Sie sicher, dass Sie die ausgewählten Bewerber löschen möchten?")

        let data = new FormData()
        this.checked.forEach(
            (checkbox) => data.append("ids[]", checkbox.value)
        )

        let token = document.querySelector('meta[name="csrf-token"]').content;

        if (confirmed) {
            fetch("/backend/applies/bulk_destroy", {
                method: "DELETE",
                headers: {
                    "X-CSRF-Token": token,
                },
                body: data,
            }).then((response) => {
                if (response.redirected) {
                    window.location.href = response.url;
                }
            });
        }
    }

    close(event) {
        // close the modal and unchecked all checkboxes
        this.modalTarget.classList.add('hidden');
        this.checked.forEach(
            (checkbox) => checkbox.checked = false
        );

        // uncheck data-checkbox-select-all-target="checkall"
        let checkall = document.getElementById("select-all-checkbox")
        checkall.checked = false

    }
    decline(event) {
        event.preventDefault()
        // write all of the checked boxes

        let data = new FormData()
        this.checked.forEach(
            (checkbox) => data.append("ids[]", checkbox.value)
        )

        let token = document.querySelector('meta[name="csrf-token"]').content;

        // check if the user is sure
        let confirmed = confirm("Sind Sie sicher, dass Sie die ausgewählten Bewerber ablehnen möchten?")

        if (!confirmed) {
            return
        } else {
            fetch("/backend/applies/bulk_decline", {
                method: "PUT",
                headers: {
                    "X-CSRF-Token": token,
                },
                body: data,
            }).then((response) => {
                if (response.redirected) {
                    window.location.href = response.url;
                }
            });
        }
    }



    moveToPool(event) {
        event.preventDefault()

        let data = new FormData()
        this.checked.forEach(
            (checkbox) => data.append("ids[]", checkbox.value)
        )

        data.append("pool_id", this.poolTarget.value)
        data.append("job_id", this.jobTarget.value)

        // get the pool name
        let pool_name = this.poolTarget.options[this.poolTarget.selectedIndex].text


        alert(this.checked.length + " Bewerber werden verschoben in " + pool_name)

        let token = document.querySelector('meta[name="csrf-token"]').content;

        fetch("/backend/applies/bulk_move_to_pool", {
            method: "PUT",
            headers: {
                "X-CSRF-Token": token,
            },
            body: data,
        }).then((response) => {
            if (response.redirected) {
                alert("🎉 Verschieben erfolgreich");
                window.location.href = response.url;
            }
            else
            {
                iziToast.error({message: "🙁Verschieben fehlgeschlagen", title: "Success", position: "topRight", timeout: 5000})
            }
        });
    }

    send_email(event) {
        event.preventDefault()

        let data = new FormData()
        this.checked.forEach(
            (checkbox) => data.append("ids[]", checkbox.value)
        )

        // open new email modal
        let modal = document.getElementById("applicants-email-modal")
        modal.classList.remove('hidden')

        // set the email applicant_ids field to the selected ids
        document.getElementById("email_applicant_ids").value = data.getAll("ids[]")
        // get the associated tomselect and set the value to the selected ids
        let select = document.getElementById("email_applicant_ids")
        let control = select.tomselect;
        // clear the select
        control.clear()
        control.sync()
        control.setValue(data.getAll("ids[]"))
    }

}
