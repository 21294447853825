import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = ["input"]

    connect() {

    }

    checkFileType() {
        const file = this.inputTarget.files[0];
        const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

        if (file) {
            if (file.type !== "application/pdf") {
                alert("Bitte laden Sie eine PDF-Datei hoch (Please upload a PDF file.)");
                this.inputTarget.value = "";  // Clear the input
            } else if (file.size > maxSizeInBytes) {
                alert("Die Datei darf nicht größer als 5 MB sein (The file must be less than 5 MB.)");
                this.inputTarget.value = "";  // Clear the input
            }
        }
    }
}
