# use or define global scope
window.Application ||= {}

map = null
infobox = null
pushpins = []

# export function to global scope
Application.initMap = () ->
  if document.getElementById('mapDiv')?
    map = new Microsoft.Maps.Map('#mapDiv'
      {
        credentials: 'AtMIDnwvmq1iWhRNRZGYghcy1uAXhvia_oBTA-J-P0kDb5UsR98Psj6GgcoleFgT'
        mapTypeId: Microsoft.Maps.MapTypeId.road
        enableSearchLogo: false
        disableZooming: false
        disableKeyboardInput: true
      }
    )

    infobox = new Microsoft.Maps.Infobox(
      new Microsoft.Maps.Location(0, 0)
      {
        visible: false
        showCloseButton: false
        offset: new Microsoft.Maps.Point(0, -4)
      }
    )

    # config from controller
    latitude = if gon.location.latitude then gon.location.latitude else 47.7582922
    longitude = if gon.location.longitude then gon.location.longitude else 9.4333549
    zoom = if gon.location.zoom then gon.location.zoom else 8

    # inital location of map
    initialLocation = new Microsoft.Maps.Location(latitude, longitude)

    # Center the map on the location
    map.setView
      center: initialLocation
      zoom: zoom

    # offices (from backend)
    if gon.offices?
      for office in gon.offices
        loc = new Microsoft.Maps.Location(
          office.latitude
          office.longitude
        )
        pin = new Microsoft.Maps.Pushpin(loc, {color: gon.pin_color})

        Microsoft.Maps.Events.addHandler(pin, 'click', handleClick)
        Microsoft.Maps.Events.addHandler(pin, 'mouseover', handleMouseOver)
        Microsoft.Maps.Events.addHandler(pin, 'mouseout', handleMouseOut)

        Microsoft.Maps.Events.addHandler(infobox, 'click', handleClick)
        Microsoft.Maps.Events.addHandler(infobox, 'mouseenter', handleMouseOver)
        Microsoft.Maps.Events.addHandler(infobox, 'mouseleave', handleMouseOut)

        # collect pins in global array
        pushpins.push(pin)

        # add pins and infofox to map
        map.entities.push(pin)
        infobox.setMap(map)

  return

handleMouseOut = (e) ->
  hideInfobox()
  map.getRootElement().style.cursor = 'default'
  return

handleMouseOver = (e) ->
  # extract location
  location = e.target.getLocation()
  displayInfobox(location)
  map.getRootElement().style.cursor = 'pointer'
  return

handleClick = (e) ->
  if e.targetType is 'pushpin'
    searchText = getTitle(e.target.getLocation())
    filterTable(searchText)
  if e.targetType is 'infobox'
    searchText = e.target.getTitle()
  filterTable(searchText)
  return

getTitle = (location) ->
  if gon.offices?
    for office in gon.offices
      # do comprehension of two locations
      if parseFloat(location.latitude) is parseFloat(office.latitude) and parseFloat(location.longitude) is parseFloat(office.longitude)
        return office.title

getLocation = (title) ->
  if gon.offices?
    for office in gon.offices
      if office.title is title
        return new Microsoft.Maps.Location(office.latitude, office.longitude)

getPushpin = (location) ->
  for pin in pushpins
    if pin.getLocation() is location
      return pin

hideInfobox = () ->
  infobox.setOptions
    visible: false
  return

displayInfobox = (location) ->
  # extract title
  title = getTitle(location)
  # place infoBox onto pin
  infobox.setLocation(location)
  # make it visible
  infobox.setOptions
    visible: true
    title: title
  return

filterTable = (text) ->
  oTable = $('#jobs_table').dataTable()
  oTable.fnFilter(text)